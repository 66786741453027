import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { ConfirmComponent } from './component/confirm/confirm.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AccountVerificationComponent } from './component/account-verification/account-verification.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LfSpinnerComponent } from './component/lf-spinner/lf-spinner.component';
import { FileUploadComponent } from './component/file-upload/file-upload.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { WebinarListComponent } from '../online-service/webinar-list/webinar-list.component';
import { SafeHtmlPipe } from '../pipes/safe-html.pipe';
import { ModuleChangerComponent } from './component/module-changer/module-changer.component';
import { LoaderDialogComponent } from './component/loader-dialog/loader-dialog.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatListModule } from '@angular/material/list';
import { DateFormatPipe } from '../pipes/date-format.pipe';

@NgModule({
  declarations: [
    ConfirmComponent,
    AccountVerificationComponent,
    LfSpinnerComponent,
    FileUploadComponent,
    WebinarListComponent,
    SafeHtmlPipe,
    DateFormatPipe,
    ModuleChangerComponent,
    LoaderDialogComponent,
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatMenuModule,
    MatRadioModule,
    MatTooltipModule,
    MatDialogModule,
    MatProgressBarModule,
    MatIconModule,
    MatDatepickerModule,
    NgxMatTimepickerModule,
    MatSlideToggleModule,
    MatListModule,
  ],
  exports: [
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatMenuModule,
    MatRadioModule,
    MatDialogModule,
    MatTooltipModule,
    MatProgressBarModule,
    LfSpinnerComponent,
    FileUploadComponent,
    MatIconModule,
    MatDatepickerModule,
    NgxMatTimepickerModule,
    WebinarListComponent,
    SafeHtmlPipe,
    DateFormatPipe,
    MatSlideToggleModule,
    MatListModule,
  ],
})
export class SharedModule { }
